<template>
  <!-- login page  -->
  <v-app v-if="openuploadlayout === false">
    <v-layout row wrap align-center justify-center>
      <v-flex lg4 md5 sm7 xs7 class="text-center">
        <img
          src="@/assets/logo_onebox-horizontal-png.png"
          width="45%"
          height="45%"
          alt="OneBox"
        />
        <br />
        <br />
        <v-progress-linear
          v-if="fail_ === false"
          background-color="light-blue lighten-4"
          color="success"
          indeterminate
          rounded
        ></v-progress-linear>
        <br />
        <p v-if="fail_ === false" class="text-center">
          {{ $t("direct_upload.pleasewait") }}
        </p>
        <p v-else class="text-center">
          <v-icon color="error">error</v-icon>&nbsp;{{ errormessage }}
        </p>
      </v-flex>
    </v-layout>
  </v-app>
  <!-- layout direct upload -->
  <v-app v-else :style="colorVapp" app>
    <!-- toolbbar -->
    <v-app-bar
      app
      elevation="0"
      color="#FFFFFF"
      style="border-bottom: 1px solid rgba(243, 245, 247, 1)"
    >
      <v-toolbar-title class="pt-1">
        <img
          src="@/assets/logo_onebox-horizontal-png.png"
          width="95"
          height="40"
          alt="One Box"
        />
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-menu transition="slide-y-transition" bottom>
          <template v-slot:activator="{ on }">
            <v-btn fab text v-on="on" small>
              <flag :iso="defaultflag" :squared="false" />
            </v-btn>
          </template>
          <v-list nav dense>
            <v-list-item
              v-for="(item, i) in language"
              :key="i"
              @click="changelang(item.data)"
            >
              <v-list-item-action>
                <flag :iso="item.flag" :squared="false" />
              </v-list-item-action>
              <v-list-item-title>{{ $t(item.name) }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>
    <!-- ปุ่มอัปโหลดของ mobile -->
    <v-app-bar
      style="position: fixed; bottom:0;  width: 100%; background-color: #e8eff2; height:60px; z-index:9"
      v-if="resolutionScreen === 220"
    >
      <v-btn
        color="red"
        height="45px"
        width="45%"
        outlined
        style="font-size: 16px; font-weight: lighter;"
        :disabled="createprogress"
        @click="cleardata()"
      >
        {{ $t("direct_upload.clearfile") }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        class="white--text"
        color="#23526c"
        style="font-size: 16px; font-weight: lighter;"
        height="45px"
        width="45%"
        :disabled="totalstackfile === 0"
        @click="fnLoopUploadFile()"
      >
        {{ $t("direct_upload.uploadfile") }}
      </v-btn>
    </v-app-bar>
    <!-- content -->
    <v-main>
      <v-container>
        <v-row no-gutters justify="center">
          <!-- จอ xl -->
          <v-card
            v-if="resolutionScreen === 800"
            style="border-radius: 25px; margin-top:10px"
            elevation="2"
            width="80%"
            height="100%"
            class="pb-3"
          >
            <v-card-text class="px-12 mx-4 mt-6 mb-n12">
              <v-layout row wrap>
                <v-flex lg12>
                  <p style="font-size: 18px">
                    {{ $t("direct_upload.savedriveof") }}
                    <!-- <span v-if="$t('default') === 'th'" style="color: #386e50; font-weight: 600;">{{
                      userdata.data_name_th
                    }}</span>
                    <span v-else-if="$t('default') === 'en'" style="color: #386e50; font-weight: 600;">{{
                      userdata.data_name_eng
                    }}</span> -->
                    <!-- ชั่วคราว -->
                    <span style="color: #386e50; font-weight: 600;">{{
                      userdata.data_name
                    }}</span>
                  </p>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-text style="max-height: auto; height: auto;">
              <v-layout>
                <v-flex lg12 class="px-8">
                  <v-checkbox
                    class="mb-n2"
                    v-model="checkhashtag"
                    dense
                    :label="$t('selectbutton.hashtag')"
                  ></v-checkbox>
                  <div class="mb-4 mt-n12 text-right">
                    <v-layout row wrap class="mx-1">
                      <v-flex lg12 v-if="totalstackfile <= 0">
                        <v-chip outlined color="#FFA000">
                          <p
                            style="font-size: 16px; font-weight: 600;"
                            class="mt-4"
                          >
                            {{ $t("direct_upload.nofilewaitupload") }}
                          </p>
                        </v-chip>
                      </v-flex>
                      <v-flex lg12 v-else>
                        <v-chip color="success">
                          <p style="font-size: 16px" class="mt-4">
                            <span style="font-weight: 600;">
                              {{ totalstackfile }}
                            </span>
                            {{
                              totalstackfile > 1
                                ? $t("direct_upload.files")
                                : $t("direct_upload.file")
                            }}
                          </p>
                        </v-chip>
                      </v-flex>
                    </v-layout>
                  </div>
                  <v-row v-if="checkhashtag === true">
                    <v-col cols="9">
                      <v-text-field
                        height="35px"
                        class="textFieldClass"
                        v-model="hashtag"
                        label="#hashtag"
                        outlined
                        dense
                        :error-messages="hashtagErrors"
                        @input="$v.hashtag.$touch()"
                        @blur="$v.hashtag.$touch()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3" class="ml-n3 pt-3">
                      <v-btn
                        class="white--text"
                        color="primary"
                        @click="addhashtag(), $v.hashtag.$reset()"
                        :disabled="hashtagErrors.length > 0 ? true : false || hashtag.length === 0"
                        height="35px"
                      >
                        {{ $t("edithashtag.add") }}</v-btn
                      >
                    </v-col>
                    <v-col cols="12">
                      <v-chip
                        v-for="(item, index) in datahashtag"
                        :key="index"
                        close
                        class="ma-1"
                        @click:close="deletehashtag(index)"
                        >#{{ item }}</v-chip
                      >
                    </v-col>
                  </v-row>
                  <!-- upload layout -->
                  <div
                    style="border-radius: 24px; position: relative;"
                    class="text-center mt-8 mx-auto"
                    id="dropzone"
                    v-if="totalstackfile > 0"
                  >
                    <div @click="ImportFiles" id="inputfile">
                      <input
                        ref="importdoc"
                        type="file"
                        @change="fnStackFile"
                        multiple
                        class="d-none"
                      />
                    </div>
                    <div id="layouthover">
                      <v-layout
                        row
                        wrap
                        justify-center
                        class="px-8 py-2"
                        style="z-index:3; overflow-y: scroll; max-height: 450px; height: auto;"
                      >
                        <v-flex
                          v-for="(item, index) in arraydataupload"
                          :key="index"
                          xl2
                          class="mt-6 px-1"
                        >
                          <v-card
                            width="100%"
                            height="195"
                            style="border-radius: 25px"
                          >
                            <v-card-text class="text-right">
                              <v-icon
                                color="error"
                                @click="fnDeleteStackFile(item)"
                              >
                                mdi-close
                              </v-icon>
                            </v-card-text>
                            <v-card-text class="mt-n4">
                              <v-icon size="70" :color="item.icon[1]">
                                {{ item.icon[0] }}
                              </v-icon>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-text>
                              <span style="font-size:14px" :title="item.name">{{
                                item.name | subStr
                              }}</span>
                            </v-card-text>
                          </v-card>
                        </v-flex>
                      </v-layout>
                      <div class="mb-1 mt-n2">
                        <span id="titleTextUploadtitle" class="mr-2">{{
                          $t("uploadfile.clicktouploadnew")
                        }}</span>
                        <span id="titleTextUploadsubtitle">{{
                          $t("uploadfile.maxsizeuploadtext")
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    :style="uploadLayout"
                    class="text-center mt-2 mx-auto"
                    @click="ImportFiles"
                    id="dropzone"
                    v-else
                  >
                    <div id="layouthover" class="pt-6">
                      <input
                        v-if="createprogress === false"
                        ref="importdoc"
                        class="d-none"
                        type="file"
                        @change="fnStackFile"
                        multiple
                        style="display:none"
                      />
                      <v-img
                        max-width="360"
                        max-height="330"
                        class="mx-auto"
                        :src="require('@/assets/img/uploadfiles.png')"
                      />
                      <span id="titleTextUploadtitle">{{
                        $t("uploadfile.clicktouploadnew")
                      }}</span>
                      <br />
                      <span id="titleTextUploadsubtitle">{{
                        $t("uploadfile.maxsizeuploadtext")
                      }}</span>
                      <br />
                      <br />
                    </div>
                  </div>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-text>
              <v-layout row wrap>
                <v-flex lg6 class="text-right pr-6">
                  <v-btn
                    color="red"
                    height="45px"
                    width="45%"
                    outlined
                    style="font-size: 16px; font-weight: lighter;"
                    :disabled="createprogress"
                    @click="cleardata()"
                  >
                    {{ $t("direct_upload.clearfile") }}
                  </v-btn>
                </v-flex>
                <v-flex lg6 class="text-left pl-6">
                  <v-btn
                    class="white--text"
                    color="#23526c"
                    style="font-size: 16px; font-weight: lighter;"
                    height="45px"
                    width="45%"
                    :disabled="totalstackfile === 0"
                    @click="fnLoopUploadFile()"
                  >
                    {{ $t("direct_upload.uploadfile") }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-text v-if="statusuploading === true">
              <v-layout class="mt-n4">
                <v-flex lg12 class="px-10">
                  <v-alert
                    border="left"
                    color="#EF9A9A"
                    dark
                    v-if="totalerror > 0"
                  >
                    <v-progress-linear
                      color="error"
                      :value="percentuploadedallfile"
                      height="30"
                    >
                      <span
                        >{{
                          statusstorage === true
                            ? $t("direct_upload.yourspacenotenough")
                            : $t("direct_upload.uploadfail") +
                              " " +
                              totalerror +
                              " "
                        }}
                        {{
                          totalerror === 1
                            ? $t("direct_upload.file")
                            : $t("direct_upload.files")
                        }}</span
                      >
                    </v-progress-linear>
                  </v-alert>
                  <v-alert
                    border="left"
                    color="#79b5d9"
                    dark
                    v-else-if="percentuploadedallfile !== 100"
                  >
                    <v-progress-linear
                      color="#204f68"
                      :value="percentuploadedallfile"
                      height="30"
                    >
                      <span>{{ parseInt(percentuploadedallfile) }}%</span>
                    </v-progress-linear>
                  </v-alert>
                  <v-alert v-else border="left" color="#b0dcca" dark>
                    <v-progress-linear color="#5cb484" value="100" height="30">
                      <span>{{ $t("direct_upload.uploadsuccess") }}</span>
                    </v-progress-linear>
                  </v-alert>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
          <!-- จอ lg -->
          <v-card
            v-else-if="resolutionScreen === 600"
            style="border-radius: 25px; margin-top:10px"
            elevation="2"
            width="100%"
            height="100%"
            class="pb-3"
          >
            <v-card-text class="px-12 mx-4 mt-6 mb-n12">
              <v-layout row wrap>
                <v-flex lg12>
                  <p style="font-size: 18px">
                    {{ $t("direct_upload.savedriveof") }}
                    <!-- <span v-if="$t('default') === 'th'" style="color: #386e50; font-weight: 600;">{{
                      userdata.data_name_th
                    }}</span>
                    <span v-else-if="$t('default') === 'en'" style="color: #386e50; font-weight: 600;">{{
                      userdata.data_name_eng
                    }}</span> -->
                    <!-- ชั่วคราว -->
                    <span style="color: #386e50; font-weight: 600;">{{
                      userdata.data_name
                    }}</span>
                  </p>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-text style="max-height: auto; height: auto;">
              <v-layout>
                <v-flex lg12 class="px-8">
                  <v-checkbox
                    class="mb-n2"
                    v-model="checkhashtag"
                    dense
                    :label="$t('selectbutton.hashtag')"
                  ></v-checkbox>
                  <div class="mb-4 mt-n12 text-right">
                    <v-layout row wrap class="mx-1">
                      <v-flex lg12 v-if="totalstackfile <= 0">
                        <v-chip outlined color="#FFA000">
                          <p
                            style="font-size: 16px; font-weight: 600;"
                            class="mt-4"
                          >
                            {{ $t("direct_upload.nofilewaitupload") }}
                          </p>
                        </v-chip>
                      </v-flex>
                      <v-flex lg12 v-else>
                        <v-chip color="success">
                          <p style="font-size: 16px" class="mt-4">
                            <span style="font-weight: 600;">
                              {{ totalstackfile }}
                            </span>
                            {{
                              totalstackfile > 1
                                ? $t("direct_upload.files")
                                : $t("direct_upload.file")
                            }}
                          </p>
                        </v-chip>
                      </v-flex>
                    </v-layout>
                  </div>
                  <v-row v-if="checkhashtag === true">
                    <v-col cols="9">
                      <v-text-field
                        height="35px"
                        class="textFieldClass"
                        v-model="hashtag"
                        label="#hashtag"
                        outlined
                        dense
                        :error-messages="hashtagErrors"
                        @input="$v.hashtag.$touch()"
                        @blur="$v.hashtag.$touch()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3" class="ml-n3 pt-3">
                      <v-btn
                        class="white--text"
                        color="primary"
                        @click="addhashtag(), $v.hashtag.$reset()"
                        :disabled="hashtagErrors.length > 0 ? true : false || hashtag.length === 0"
                        height="35px"
                      >
                        {{ $t("edithashtag.add") }}</v-btn
                      >
                    </v-col>
                    <v-col cols="12">
                      <v-chip
                        v-for="(item, index) in datahashtag"
                        :key="index"
                        close
                        class="ma-1"
                        @click:close="deletehashtag(index)"
                        >#{{ item }}</v-chip
                      >
                    </v-col>
                  </v-row>
                  <!-- upload layout -->
                  <div
                    style="border-radius: 24px; position: relative;"
                    class="text-center mt-10 mx-auto"
                    id="dropzone"
                    v-if="totalstackfile > 0"
                  >
                    <div @click="ImportFiles" id="inputfile">
                      <input
                        ref="importdoc"
                        type="file"
                        @change="fnStackFile"
                        multiple
                        class="d-none"
                      />
                    </div>
                    <div id="layouthover">
                      <v-layout
                        row
                        wrap
                        justify-center
                        class="px-8 py-2"
                        style="overflow-y: scroll; max-height: 450px; height: auto;"
                      >
                        <v-flex
                          v-for="(item, index) in arraydataupload"
                          :key="index"
                          lg2
                          class="mt-6 px-1"
                        >
                          <v-card
                            width="100%"
                            height="195"
                            style="border-radius: 25px"
                          >
                            <v-card-text class="text-right">
                              <v-icon
                                color="error"
                                @click="fnDeleteStackFile(item)"
                              >
                                mdi-close
                              </v-icon>
                            </v-card-text>
                            <v-card-text class="mt-n4">
                              <v-icon size="70" :color="item.icon[1]">
                                {{ item.icon[0] }}
                              </v-icon>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-text>
                              <span style="font-size:14px" :title="item.name">{{
                                item.name | subStr
                              }}</span>
                            </v-card-text>
                          </v-card>
                        </v-flex>
                      </v-layout>
                      <div class="mb-1 mt-n2">
                        <span id="titleTextUploadtitle" class="mr-2">{{
                          $t("uploadfile.clicktouploadnew")
                        }}</span>
                        <span id="titleTextUploadsubtitle">{{
                          $t("uploadfile.maxsizeuploadtext")
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    :style="uploadLayout"
                    class="text-center mt-10 mx-auto"
                    @click="ImportFiles"
                    id="dropzone"
                    v-else
                  >
                    <div id="layouthover" class="pt-6">
                      <input
                        ref="importdoc"
                        class="d-none"
                        type="file"
                        @change="fnStackFile"
                        multiple
                        style="display:none;"
                      />
                      <v-img
                        max-width="360"
                        max-height="330"
                        class="mx-auto"
                        :src="require('@/assets/img/uploadfiles.png')"
                      />
                      <span id="titleTextUploadtitle">{{
                        $t("uploadfile.clicktouploadnew")
                      }}</span>
                      <br />
                      <span id="titleTextUploadsubtitle">{{
                        $t("uploadfile.maxsizeuploadtext")
                      }}</span>
                      <br />
                      <br />
                    </div>
                  </div>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-text>
              <v-layout row wrap>
                <v-flex lg6 class="text-right pr-6">
                  <v-btn
                    color="red"
                    height="45px"
                    width="45%"
                    outlined
                    style="font-size: 16px; font-weight: lighter;"
                    :disabled="createprogress"
                    @click="cleardata()"
                  >
                    {{ $t("direct_upload.clearfile") }}
                  </v-btn>
                </v-flex>
                <v-flex lg6 class="text-left pl-6">
                  <v-btn
                    class="white--text"
                    color="#23526c"
                    style="font-size: 16px; font-weight: lighter;"
                    height="45px"
                    width="45%"
                    :disabled="totalstackfile === 0"
                    @click="fnLoopUploadFile()"
                  >
                    {{ $t("direct_upload.uploadfile") }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-text v-if="statusuploading === true">
              <v-layout class="mt-n4">
                <v-flex lg12 class="px-10">
                  <v-alert
                    border="left"
                    color="#EF9A9A"
                    dark
                    v-if="totalerror > 0"
                  >
                    <v-progress-linear
                      color="error"
                      :value="percentuploadedallfile"
                      height="30"
                    >
                      <span
                        >{{
                          statusstorage === true
                            ? $t("direct_upload.yourspacenotenough")
                            : $t("direct_upload.uploadfail") +
                              " " +
                              totalerror +
                              " "
                        }}
                        {{
                          totalerror === 1
                            ? $t("direct_upload.file")
                            : $t("direct_upload.files")
                        }}</span
                      >
                    </v-progress-linear>
                  </v-alert>
                  <v-alert
                    border="left"
                    color="#79b5d9"
                    dark
                    v-else-if="percentuploadedallfile !== 100"
                  >
                    <v-progress-linear
                      color="#204f68"
                      :value="percentuploadedallfile"
                      height="30"
                    >
                      <span>{{ parseInt(percentuploadedallfile) }}%</span>
                    </v-progress-linear>
                  </v-alert>
                  <v-alert v-else border="left" color="#b0dcca" dark>
                    <v-progress-linear color="#5cb484" value="100" height="30">
                      <span>{{ $t("direct_upload.uploadsuccess") }}</span>
                    </v-progress-linear>
                  </v-alert>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
          <!-- จอ md portrait-->
          <v-card
            v-else-if="resolutionScreen === 500 && checkorientation === false"
            style="margin-top: 80px; border-radius: 25px; padding-top: 30px; padding-bottom: 70px;"
            elevation="2"
            width="100%"
            height="100%"
          >
            <v-card-text class="px-6 mx-6 pt-2 mb-n8">
              <v-layout>
                <v-flex lg12>
                  <p style="font-size: 16px">
                    {{ $t("direct_upload.savedriveof") }}
                    <!-- <span v-if="$t('default') === 'th'" style="color: #386e50; font-weight: 600;">{{
                      userdata.data_name_th
                    }}</span>
                    <span v-else-if="$t('default') === 'en'" style="color: #386e50; font-weight: 600;">{{
                      userdata.data_name_eng
                    }}</span> -->
                    <!-- ชั่วคราว -->
                    <span style="color: #386e50; font-weight: 600;">{{
                      userdata.data_name
                    }}</span>
                  </p>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-text style="max-height: auto; height: auto;">
              <v-layout>
                <v-flex lg12 class="px-6 mb-12">
                  <v-checkbox
                    v-model="checkhashtag"
                    dense
                    :label="$t('selectbutton.hashtag')"
                  ></v-checkbox>
                  <div class="mb-4 mt-n12 text-right">
                    <v-layout row wrap class="mx-1">
                      <v-flex lg12 v-if="totalstackfile <= 0">
                        <v-chip outlined color="#FFA000">
                          <p
                            style="font-size: 16px; font-weight: 600;"
                            class="mt-4"
                          >
                            {{ $t("direct_upload.nofilewaitupload") }}
                          </p>
                        </v-chip>
                      </v-flex>
                      <v-flex lg12 v-else>
                        <v-chip color="success">
                          <p style="font-size: 16px" class="mt-4">
                            <span style="font-weight: 600;">
                              {{ totalstackfile }}
                            </span>
                            {{
                              totalstackfile > 1
                                ? $t("direct_upload.files")
                                : $t("direct_upload.file")
                            }}
                          </p>
                        </v-chip>
                      </v-flex>
                    </v-layout>
                  </div>
                  <v-row v-if="checkhashtag === true">
                    <v-col cols="9">
                      <v-text-field
                        height="35px"
                        class="textFieldClass"
                        v-model="hashtag"
                        label="#hashtag"
                        outlined
                        dense
                        :error-messages="hashtagErrors"
                        @input="$v.hashtag.$touch()"
                        @blur="$v.hashtag.$touch()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3" class="ml-n3 pt-3">
                      <v-btn
                        class="white--text"
                        color="primary"
                        @click="addhashtag(), $v.hashtag.$reset()"
                        :disabled="hashtagErrors.length > 0 ? true : false || hashtag.length === 0"
                        height="35px"
                      >
                        {{ $t("edithashtag.add") }}</v-btn
                      >
                    </v-col>
                    <v-col cols="12">
                      <v-chip
                        v-for="(item, index) in datahashtag"
                        :key="index"
                        close
                        class="ma-1"
                        @click:close="deletehashtag(index)"
                        >#{{ item }}</v-chip
                      >
                    </v-col>
                  </v-row>
                  <!-- upload layout -->
                  <div
                    style="border-radius: 24px; position: relative;"
                    class="text-center mt-12 mx-auto"
                    id="dropzone"
                    v-if="totalstackfile > 0"
                  >
                    <div @click="ImportFiles" id="inputfile">
                      <input
                        ref="importdoc"
                        type="file"
                        @change="fnStackFile"
                        multiple
                        class="d-none"
                      />
                    </div>
                    <div id="layouthover">
                      <v-layout
                        row
                        wrap
                        justify-center
                        class="px-8 py-2"
                        style="z-index:3; overflow-y: scroll; max-height: 690px; height: auto;"
                      >
                        <v-flex
                          v-for="(item, index) in arraydataupload"
                          :key="index"
                          md3
                          class="mt-6 px-1"
                        >
                          <v-card
                            width="100%"
                            height="195"
                            style="border-radius: 25px"
                          >
                            <v-card-text class="text-right">
                              <v-icon
                                color="error"
                                @click="fnDeleteStackFile(item)"
                              >
                                mdi-close
                              </v-icon>
                            </v-card-text>
                            <v-card-text class="mt-n4">
                              <v-icon size="70" :color="item.icon[1]">
                                {{ item.icon[0] }}
                              </v-icon>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-text>
                              <span style="font-size:14px" :title="item.name">{{
                                item.name | subStr
                              }}</span>
                            </v-card-text>
                          </v-card>
                        </v-flex>
                      </v-layout>
                      <div class="mb-1 mt-n2">
                        <span id="titleTextUploadtitle" class="mr-2">{{
                          $t("uploadfile.clicktouploadnew")
                        }}</span>
                        <span id="titleTextUploadsubtitle">{{
                          $t("uploadfile.maxsizeuploadtext")
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    :style="uploadLayout"
                    class="text-center mt-12 mx-auto py-12"
                    @click="ImportFiles"
                    id="dropzone"
                    v-else
                  >
                    <div id="layouthover">
                      <input
                        ref="importdoc"
                        class="d-none"
                        type="file"
                        @change="fnStackFile"
                        multiple
                        style="display:none"
                      />
                      <v-img
                        max-width="500"
                        max-height="470"
                        class="mx-auto"
                        :src="require('@/assets/img/uploadfiles.png')"
                      />
                      <span id="titleTextUploadtitle" style="font-size: 24px">{{
                        $t("uploadfile.clicktouploadnew")
                      }}</span>
                      <br />
                      <span
                        id="titleTextUploadsubtitle"
                        style="font-size: 22px"
                        >{{ $t("uploadfile.maxsizeuploadtext") }}</span
                      >
                      <br />
                      <br />
                    </div>
                  </div>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-text class="mt-n8 pb-n6">
              <v-layout row wrap>
                <v-flex lg6 class="text-right pr-6">
                  <v-btn
                    color="red"
                    height="45px"
                    width="45%"
                    outlined
                    style="font-size: 16px; font-weight: lighter;"
                    :disabled="createprogress"
                    @click="cleardata()"
                  >
                    {{ $t("direct_upload.clearfile") }}
                  </v-btn>
                </v-flex>
                <v-flex lg6 class="text-left pl-6">
                  <v-btn
                    class="white--text"
                    color="#23526c"
                    style="font-size: 16px; font-weight: lighter;"
                    height="45px"
                    width="45%"
                    :disabled="totalstackfile === 0"
                    @click="fnLoopUploadFile()"
                  >
                    {{ $t("direct_upload.uploadfile") }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-text v-if="statusuploading === true">
              <v-layout class="">
                <v-flex lg12 class="px-6">
                  <v-alert
                    border="left"
                    color="#EF9A9A"
                    dark
                    v-if="totalerror > 0"
                  >
                    <v-progress-linear
                      color="error"
                      :value="percentuploadedallfile"
                      height="30"
                    >
                      <span
                        >{{
                          statusstorage === true
                            ? $t("direct_upload.yourspacenotenough")
                            : $t("direct_upload.uploadfail") +
                              " " +
                              totalerror +
                              " "
                        }}
                        {{
                          totalerror === 1
                            ? $t("direct_upload.file")
                            : $t("direct_upload.files")
                        }}</span
                      >
                    </v-progress-linear>
                  </v-alert>
                  <v-alert
                    border="left"
                    color="#79b5d9"
                    dark
                    v-else-if="percentuploadedallfile !== 100"
                  >
                    <v-progress-linear
                      color="#204f68"
                      :value="percentuploadedallfile"
                      height="30"
                    >
                      <span>{{ parseInt(percentuploadedallfile) }}%</span>
                    </v-progress-linear>
                  </v-alert>
                  <v-alert v-else border="left" color="#b0dcca" dark>
                    <v-progress-linear color="#5cb484" value="100" height="30">
                      <span>{{ $t("direct_upload.uploadsuccess") }}</span>
                    </v-progress-linear>
                  </v-alert>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
          <!-- จอ md landscape-->
          <v-card
            v-else-if="resolutionScreen === 500 && checkorientation === true"
            style="margin-top: 10px; border-radius: 25px; padding-top: 10px;"
            elevation="2"
            width="100%"
            height="100%"
          >
            <v-card-text class="px-6 mx-6 pt-2 mb-n12">
              <v-layout>
                <v-flex lg12>
                  <p style="font-size: 16px">
                    {{ $t("direct_upload.savedriveof") }}
                    <!-- <span v-if="$t('default') === 'th'" style="color: #386e50; font-weight: 600;">{{
                      userdata.data_name_th
                    }}</span>
                    <span v-else-if="$t('default') === 'en'" style="color: #386e50; font-weight: 600;">{{
                      userdata.data_name_eng
                    }}</span> -->
                    <!-- ชั่วคราว -->
                    <span style="color: #386e50; font-weight: 600;">{{
                      userdata.data_name
                    }}</span>
                  </p>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-text style="max-height: auto; height: auto;">
              <v-layout>
                <v-flex lg12 class="px-6 mb-6">
                  <v-checkbox
                    class="checkboxStyle"
                    v-model="checkhashtag"
                    dense
                    :label="$t('selectbutton.hashtag')"
                  ></v-checkbox>
                  <div class="mb-4 mt-n12 text-right">
                    <v-layout row wrap class="mx-1">
                      <v-flex lg12 v-if="totalstackfile <= 0">
                        <v-chip outlined color="#FFA000" small="">
                          <p
                            style="font-size: 14px; font-weight: 600;"
                            class="mt-4"
                          >
                            {{ $t("direct_upload.nofilewaitupload") }}
                          </p>
                        </v-chip>
                      </v-flex>
                      <v-flex lg12 v-else>
                        <v-chip color="success">
                          <p style="font-size: 16px" class="mt-4">
                            <span style="font-weight: 600;">
                              {{ totalstackfile }}
                            </span>
                            {{
                              totalstackfile > 1
                                ? $t("direct_upload.files")
                                : $t("direct_upload.file")
                            }}
                          </p>
                        </v-chip>
                      </v-flex>
                    </v-layout>
                  </div>
                  <v-row v-if="checkhashtag === true">
                    <v-col cols="9">
                      <v-text-field
                        height="35px"
                        class="textFieldClass"
                        v-model="hashtag"
                        label="#hashtag"
                        outlined
                        dense
                        :error-messages="hashtagErrors"
                        @input="$v.hashtag.$touch()"
                        @blur="$v.hashtag.$touch()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3" class="ml-n3 pt-3">
                      <v-btn
                        class="white--text"
                        color="primary"
                        @click="addhashtag(), $v.hashtag.$reset()"
                        :disabled="hashtagErrors.length > 0 ? true : false || hashtag.length === 0"
                        height="35px"
                      >
                        {{ $t("edithashtag.add") }}</v-btn
                      >
                    </v-col>
                    <v-col cols="12">
                      <v-chip
                        v-for="(item, index) in datahashtag"
                        :key="index"
                        close
                        class="ma-1"
                        @click:close="deletehashtag(index)"
                        >#{{ item }}</v-chip
                      >
                    </v-col>
                  </v-row>
                  <!-- upload layout -->
                  <div
                    style="border-radius: 24px; position: relative;"
                    class="text-center mt-6 mx-auto"
                    id="dropzone"
                    v-if="totalstackfile > 0"
                  >
                    <div @click="ImportFiles" id="inputfile">
                      <input
                        ref="importdoc"
                        type="file"
                        @change="fnStackFile"
                        multiple
                        class="d-none"
                      />
                    </div>
                    <div id="layouthover">
                      <v-layout
                        row
                        wrap
                        justify-center
                        class="px-8 py-2"
                        style="z-index:3; overflow-y: scroll; max-height: 300px; height: auto;"
                      >
                        <v-flex
                          v-for="(item, index) in arraydataupload"
                          :key="index"
                          md3
                          class="mt-6 px-1"
                        >
                          <v-card
                            width="100%"
                            height="195"
                            style="border-radius: 25px"
                          >
                            <v-card-text class="text-right">
                              <v-icon
                                color="error"
                                @click="fnDeleteStackFile(item)"
                              >
                                mdi-close
                              </v-icon>
                            </v-card-text>
                            <v-card-text class="mt-n4">
                              <v-icon size="70" :color="item.icon[1]">
                                {{ item.icon[0] }}
                              </v-icon>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-text>
                              <span style="font-size:14px" :title="item.name">{{
                                item.name | subStr
                              }}</span>
                            </v-card-text>
                          </v-card>
                        </v-flex>
                      </v-layout>
                      <div class="mb-1 mt-n2">
                        <span id="titleTextUploadtitle" class="mr-2">{{
                          $t("uploadfile.clicktouploadnew")
                        }}</span>
                        <span id="titleTextUploadsubtitle">{{
                          $t("uploadfile.maxsizeuploadtext")
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    :style="uploadLayout"
                    class="text-center mt-6 mx-auto pt-2"
                    @click="ImportFiles"
                    id="dropzone"
                    v-else
                  >
                    <div id="layouthover">
                      <input
                        ref="importdoc"
                        class="d-none"
                        type="file"
                        @change="fnStackFile"
                        multiple
                        style="display:none"
                      />
                      <v-img
                        max-width="230"
                        max-height="200"
                        class="mx-auto"
                        :src="require('@/assets/img/uploadfiles.png')"
                      />
                      <span id="titleTextUploadtitle" style="font-size: 16px">{{
                        $t("uploadfile.clicktouploadnew")
                      }}</span>
                      <br />
                      <span
                        id="titleTextUploadsubtitle"
                        style="font-size: 14px"
                        >{{ $t("uploadfile.maxsizeuploadtext") }}</span
                      >
                      <br />
                      <br />
                    </div>
                  </div>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-text class="mt-n8">
              <v-layout row wrap>
                <v-flex lg6 class="text-right pr-6">
                  <v-btn
                    color="red"
                    height="45px"
                    width="45%"
                    outlined
                    style="font-size: 16px; font-weight: lighter;"
                    :disabled="createprogress"
                    @click="cleardata()"
                  >
                    {{ $t("direct_upload.clearfile") }}
                  </v-btn>
                </v-flex>
                <v-flex lg6 class="text-left pl-6">
                  <v-btn
                    class="white--text"
                    color="#23526c"
                    style="font-size: 16px; font-weight: lighter;"
                    height="45px"
                    width="45%"
                    :disabled="totalstackfile === 0"
                    @click="fnLoopUploadFile()"
                  >
                    {{ $t("direct_upload.uploadfile") }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-text class="mt-n4" v-if="statusuploading === true">
              <v-layout>
                <v-flex lg12 class="px-6">
                  <v-alert
                    border="left"
                    color="#EF9A9A"
                    dark
                    v-if="totalerror > 0"
                  >
                    <v-progress-linear
                      color="error"
                      :value="percentuploadedallfile"
                      height="30"
                    >
                      <span
                        >{{
                          statusstorage === true
                            ? $t("direct_upload.yourspacenotenough")
                            : $t("direct_upload.uploadfail") +
                              " " +
                              totalerror +
                              " "
                        }}
                        {{
                          totalerror === 1
                            ? $t("direct_upload.file")
                            : $t("direct_upload.files")
                        }}</span
                      >
                    </v-progress-linear>
                  </v-alert>
                  <v-alert
                    border="left"
                    color="#79b5d9"
                    dark
                    v-else-if="percentuploadedallfile !== 100"
                  >
                    <v-progress-linear
                      color="#204f68"
                      :value="percentuploadedallfile"
                      height="30"
                    >
                      <span>{{ parseInt(percentuploadedallfile) }}%</span>
                    </v-progress-linear>
                  </v-alert>
                  <v-alert v-else border="left" color="#b0dcca" dark>
                    <v-progress-linear color="#5cb484" value="100" height="30">
                      <span>{{ $t("direct_upload.uploadsuccess") }}</span>
                    </v-progress-linear>
                  </v-alert>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
          <!-- จอ sm portrait -->
          <v-card
            v-else-if="resolutionScreen === 400 && checkorientation === false"
            style="margin-top: 10px; border-radius: 25px;"
            elevation="2"
            width="100%"
            height="100%"
            class="pb-4 mx-6"
          >
            <v-card-text class="pl-6 mx-6 pt-6 mb-n8">
              <v-layout>
                <v-flex lg12>
                  <p style="font-size: 16px">
                    {{ $t("direct_upload.savedriveof") }}
                    <!-- <span v-if="$t('default') === 'th'" style="color: #386e50; font-weight: 600;">{{
                      userdata.data_name_th
                    }}</span>
                    <span v-else-if="$t('default') === 'en'" style="color: #386e50; font-weight: 600;">{{
                      userdata.data_name_eng
                    }}</span> -->
                    <!-- ชั่วคราว -->
                    <span style="color: #386e50; font-weight: 600;">{{
                      userdata.data_name
                    }}</span>
                  </p>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-text style="max-height: auto; height: auto;" class="mb-n12">
              <v-layout>
                <v-flex lg12 class="px-6 mb-12">
                  <v-checkbox
                    class="checkboxStyle"
                    v-model="checkhashtag"
                    dense
                    :label="$t('selectbutton.hashtag')"
                  ></v-checkbox>
                  <div class="mt-n12 mb-4 text-right">
                    <v-layout row wrap class="mx-1">
                      <v-flex lg12 v-if="totalstackfile <= 0">
                        <v-chip outlined color="#FFA000">
                          <p
                            style="font-size: 16px; font-weight: 600;"
                            class="mt-4"
                          >
                            {{ $t("direct_upload.nofilewaitupload") }}
                          </p>
                        </v-chip>
                      </v-flex>
                      <v-flex lg12 v-else>
                        <v-chip color="success">
                          <p style="font-size: 16px" class="mt-4">
                            <span style="font-weight: 600;">
                              {{ totalstackfile }}
                            </span>
                            {{
                              totalstackfile > 1
                                ? $t("direct_upload.files")
                                : $t("direct_upload.file")
                            }}
                          </p>
                        </v-chip>
                      </v-flex>
                    </v-layout>
                  </div>
                  <v-row v-if="checkhashtag === true">
                    <v-col cols="9" class="mt-n2">
                      <v-text-field
                        height="35px"
                        class="textFieldClass"
                        v-model="hashtag"
                        label="#hashtag"
                        outlined
                        dense
                        :error-messages="hashtagErrors"
                        @input="$v.hashtag.$touch()"
                        @blur="$v.hashtag.$touch()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3" class="ml-n3 pt-1">
                      <v-btn
                        class="white--text"
                        color="primary"
                        @click="addhashtag(), $v.hashtag.$reset()"
                        :disabled="hashtagErrors.length > 0 ? true : false || hashtag.length === 0"
                        height="35px"
                      >
                        {{ $t("edithashtag.add") }}</v-btn
                      >
                    </v-col>
                    <v-col cols="12" class="mt-n2 ml-n1">
                      <v-chip
                        v-for="(item, index) in datahashtag"
                        :key="index"
                        close
                        class="ma-1"
                        @click:close="deletehashtag(index)"
                        >#{{ item }}</v-chip
                      >
                    </v-col>
                  </v-row>
                  <!-- upload layout -->
                  <div
                    style="border-radius: 24px; position: relative;"
                    class="text-center mt-8 mx-auto"
                    id="dropzone"
                    v-if="totalstackfile > 0"
                  >
                    <div @click="ImportFiles" id="inputfile">
                      <input
                        ref="importdoc"
                        type="file"
                        @change="fnStackFile"
                        multiple
                        class="d-none"
                      />
                    </div>
                    <div id="layouthover">
                      <v-layout
                        row
                        wrap
                        justify-center
                        class="px-8 py-2"
                        style="z-index:3; overflow-y: scroll; max-height: 500px; height: auto;"
                      >
                        <v-flex
                          v-for="(item, index) in arraydataupload"
                          :key="index"
                          sm4
                          class="mt-6 px-1"
                        >
                          <v-card
                            width="100%"
                            height="195"
                            style="border-radius: 25px"
                          >
                            <v-card-text class="text-right">
                              <v-icon
                                color="error"
                                @click="fnDeleteStackFile(item)"
                              >
                                mdi-close
                              </v-icon>
                            </v-card-text>
                            <v-card-text class="mt-n4">
                              <v-icon size="70" :color="item.icon[1]">
                                {{ item.icon[0] }}
                              </v-icon>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-text>
                              <span style="font-size:14px" :title="item.name">{{
                                item.name | subStr
                              }}</span>
                            </v-card-text>
                          </v-card>
                        </v-flex>
                      </v-layout>
                      <div class="mb-1 mt-n2">
                        <span id="titleTextUploadtitle" class="mr-2">{{
                          $t("uploadfile.clicktouploadnew")
                        }}</span>
                        <span id="titleTextUploadsubtitle">{{
                          $t("uploadfile.maxsizeuploadtext")
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    :style="uploadLayout"
                    class="text-center mt-8 mx-auto py-12"
                    @click="ImportFiles"
                    id="dropzone"
                    v-else
                  >
                    <div id="layouthover">
                      <input
                        ref="importdoc"
                        class="d-none"
                        type="file"
                        @change="fnStackFile"
                        multiple
                        style="display:none"
                      />
                      <v-img
                        max-width="330"
                        max-height="310"
                        class="mx-auto"
                        :src="require('@/assets/img/uploadfiles.png')"
                      />
                      <span id="titleTextUploadtitle" style="font-size: 24px">{{
                        $t("uploadfile.clicktouploadnew")
                      }}</span>
                      <br />
                      <span
                        id="titleTextUploadsubtitle"
                        style="font-size: 22px"
                        >{{ $t("uploadfile.maxsizeuploadtext") }}</span
                      >
                      <br />
                      <br />
                    </div>
                  </div>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-text class="mt-n8 pb-n6">
              <v-layout row wrap>
                <v-flex lg6 class="text-right pr-6">
                  <v-btn
                    color="red"
                    height="45px"
                    width="45%"
                    outlined
                    style="font-size: 16px; font-weight: lighter;"
                    :disabled="createprogress"
                    @click="cleardata()"
                  >
                    {{ $t("direct_upload.clearfile") }}
                  </v-btn>
                </v-flex>
                <v-flex lg6 class="text-left pl-6">
                  <v-btn
                    class="white--text"
                    color="#23526c"
                    style="font-size: 16px; font-weight: lighter;"
                    height="45px"
                    width="45%"
                    :disabled="totalstackfile === 0"
                    @click="fnLoopUploadFile()"
                  >
                    {{ $t("direct_upload.uploadfile") }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-text v-if="statusuploading === true" class="mt-2">
              <v-layout>
                <v-flex lg12 class="px-6">
                  <v-alert
                    border="left"
                    color="#EF9A9A"
                    dark
                    v-if="totalerror > 0"
                  >
                    <v-progress-linear
                      color="error"
                      :value="percentuploadedallfile"
                      height="30"
                    >
                      <span
                        >{{
                          statusstorage === true
                            ? $t("direct_upload.yourspacenotenough")
                            : $t("direct_upload.uploadfail") +
                              " " +
                              totalerror +
                              " "
                        }}
                        {{
                          totalerror === 1
                            ? $t("direct_upload.file")
                            : $t("direct_upload.files")
                        }}</span
                      >
                    </v-progress-linear>
                  </v-alert>
                  <v-alert
                    border="left"
                    color="#79b5d9"
                    dark
                    v-else-if="percentuploadedallfile !== 100"
                  >
                    <v-progress-linear
                      color="#204f68"
                      :value="percentuploadedallfile"
                      height="30"
                    >
                      <span>{{ parseInt(percentuploadedallfile) }}%</span>
                    </v-progress-linear>
                  </v-alert>
                  <v-alert v-else border="left" color="#b0dcca" dark>
                    <v-progress-linear color="#5cb484" value="100" height="30">
                      <span>{{ $t("direct_upload.uploadsuccess") }}</span>
                    </v-progress-linear>
                  </v-alert>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
          <!-- จอ sm landscape -->
          <v-card
            v-else-if="resolutionScreen === 400 && checkorientation === true"
            style="margin-top: 2px; border-radius: 25px; overflow:hidden;"
            elevation="2"
            width="100%"
            height="100%"
            class="pb-1"
          >
            <v-layout row wrap style="overflow:hidden;" class="py-8">
              <v-flex sm7>
                <v-card-text
                  style="max-height: auto; height: auto;"
                  class="my-n12 mr-n6"
                >
                  <v-layout>
                    <v-flex lg12 class="pl-2">
                      <!-- upload layout -->
                      <div
                        style="border-radius: 24px; position: relative;"
                        class="text-center mt-2 mx-auto"
                        id="dropzone"
                        v-if="totalstackfile > 0"
                      >
                        <div @click="ImportFiles" id="inputfile">
                          <input
                            ref="importdoc"
                            type="file"
                            @change="fnStackFile"
                            multiple
                            class="d-none"
                          />
                        </div>
                        <v-list
                          class="pt-2"
                          id="layouthover"
                          dense
                          style="z-index:3; overflow-y: scroll; height: 180px;"
                        >
                          <v-list-item
                            v-for="item in arraydataupload"
                            :key="item.id"
                            class="my-n4"
                          >
                            <v-list-item-action class="mr-4">
                              <v-icon large :color="item.icon[1]">{{
                                item.icon[0]
                              }}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content class="mr-n2 text-left">
                              <v-list-item-title style="font-size: 14px;">
                                {{ item.name }}
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action class="text-right">
                              <v-icon
                                class="ml-2"
                                @click="fnDeleteStackFile(item)"
                                color="red"
                                >mdi-close</v-icon
                              >
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                        <div class="mb-n6">
                          <span
                            id="titleTextUploadtitle"
                            class="mr-2"
                            style="font-size:16px;"
                            >{{ $t("uploadfile.clicktouploadnew") }}</span
                          >
                        </div>
                        <dir>
                          <span
                            id="titleTextUploadsubtitle"
                            style="font-size:14px;"
                            >{{ $t("uploadfile.maxsizeuploadtext") }}</span
                          >
                        </dir>
                      </div>
                      <div
                        :style="uploadLayout"
                        class="text-center mt-3 pb-2 mx-auto"
                        @click="ImportFiles"
                        id="dropzone"
                        v-else
                      >
                        <div id="layouthover">
                          <input
                            ref="importdoc"
                            class="d-none"
                            type="file"
                            @change="fnStackFile"
                            multiple
                            style="display:none"
                          />
                          <v-img
                            max-width="180"
                            max-height="160"
                            class="mx-auto"
                            :src="require('@/assets/img/uploadfiles.png')"
                          />
                          <span
                            id="titleTextUploadtitle"
                            style="font-size:16px;"
                            >{{ $t("uploadfile.clicktouploadnew") }}</span
                          >
                          <br />
                          <span
                            id="titleTextUploadsubtitle"
                            style="font-size:14px;"
                            >{{ $t("uploadfile.maxsizeuploadtext") }}</span
                          >
                        </div>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-flex>
              <v-flex sm5>
                <v-card-text class="mt-n8 mb-n8">
                  <v-layout>
                    <v-flex lg12>
                      <p style="font-size: 14px">
                        {{ $t("direct_upload.savedriveof") }}
                        <!-- <span v-if="$t('default') === 'th'" style="color: #386e50; font-weight: 600;">{{
                          userdata.data_name_th
                        }}</span>
                        <span v-else-if="$t('default') === 'en'" style="color: #386e50; font-weight: 600;">{{
                          userdata.data_name_eng
                        }}</span> -->
                        <!-- ชั่วคราว -->
                        <span style="color: #386e50; font-weight: 600;">{{
                          userdata.data_name
                        }}</span>
                      </p>
                    </v-flex>
                  </v-layout>
                </v-card-text>
                <v-card-text>
                  <div class="mb-6 mt-n6">
                    <v-layout row wrap>
                      <v-flex sm6 class="pl-2">
                        <v-checkbox
                          class="checkboxStyle"
                          v-model="checkhashtag"
                          dense
                          :label="$t('selectbutton.hashtag')"
                        ></v-checkbox>
                      </v-flex>
                      <v-flex
                        sm6
                        v-if="totalstackfile <= 0"
                        class="text-right ml-n6"
                      >
                        <v-chip outlined color="#FFA000" small class="mb-n8">
                          <p
                            style="font-size: 14px; font-weight: 600;"
                            class="mt-4"
                          >
                            {{ $t("direct_upload.nofilewaitupload") }}
                          </p>
                        </v-chip>
                      </v-flex>
                      <v-flex sm6 v-else class="text-right ml-n6">
                        <v-chip color="success" small class="mb-n8">
                          <p style="font-size: 14px" class="mt-4">
                            <span style="font-weight: 600;">
                              {{ totalstackfile }}
                            </span>
                            {{
                              totalstackfile > 1
                                ? $t("direct_upload.files")
                                : $t("direct_upload.file")
                            }}
                          </p>
                        </v-chip>
                      </v-flex>
                    </v-layout>
                  </div>
                  <v-row class="my-n6" v-if="checkhashtag === true">
                    <v-col cols="9" class="mt-n2">
                      <v-text-field
                        height="30px"
                        class="textFieldClass"
                        v-model="hashtag"
                        label="#hashtag"
                        outlined
                        dense
                        :error-messages="hashtagErrors"
                        @input="$v.hashtag.$touch()"
                        @blur="$v.hashtag.$touch()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3" class="ml-n3 pt-1">
                      <v-btn
                        class="white--text"
                        color="primary"
                        @click="addhashtag(), $v.hashtag.$reset()"
                        :disabled="hashtagErrors.length > 0 ? true : false || hashtag.length === 0"
                        height="38px"
                      >
                        {{ $t("edithashtag.add") }}</v-btn
                      >
                    </v-col>
                    <v-col
                      cols="12"
                      class="mt-n2 ml-n1"
                      style="height:80px; overflow-y:scroll;"
                    >
                      <v-chip
                        v-for="(item, index) in datahashtag"
                        :key="index"
                        close
                        class="ma-1"
                        @click:close="deletehashtag(index)"
                        >#{{ item }}</v-chip
                      >
                    </v-col>
                  </v-row>
                  <v-row
                    v-else-if="statusuploading === true"
                    style="height:75px"
                  >
                    <v-col cols="12">
                      <v-alert
                        border="left"
                        color="#EF9A9A"
                        dark
                        v-if="totalerror > 0"
                      >
                        <v-progress-linear
                          color="error"
                          :value="percentuploadedallfile"
                          height="30"
                        >
                          <span
                            >{{
                              statusstorage === true
                                ? $t("direct_upload.yourspacenotenough")
                                : $t("direct_upload.uploadfail") +
                                  " " +
                                  totalerror +
                                  " "
                            }}
                            {{
                              totalerror === 1
                                ? $t("direct_upload.file")
                                : $t("direct_upload.files")
                            }}</span
                          >
                        </v-progress-linear>
                      </v-alert>
                      <v-alert
                        border="left"
                        color="#79b5d9"
                        dark
                        v-else-if="percentuploadedallfile !== 100"
                        class="mt-6"
                      >
                        <v-progress-linear
                          color="#204f68"
                          :value="percentuploadedallfile"
                          height="17"
                        >
                          <span style="font-size:14px"
                            >{{ parseInt(percentuploadedallfile) }}%</span
                          >
                        </v-progress-linear>
                      </v-alert>
                      <v-alert
                        v-else
                        border="left"
                        color="#b0dcca"
                        dark
                        class="mt-6"
                      >
                        <v-progress-linear
                          color="#5cb484"
                          value="100"
                          height="17"
                        >
                          <span style="font-size:14px">{{
                            $t("direct_upload.uploadsuccess")
                          }}</span>
                        </v-progress-linear>
                      </v-alert>
                    </v-col>
                  </v-row>
                  <v-row v-else style="height:75px">
                    <v-col cols="12"> </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text class="mb-n8">
                  <v-layout row wrap>
                    <v-flex sm6 class="text-right pr-6">
                      <v-btn
                        color="red"
                        height="30px"
                        width="80%"
                        outlined
                        style="font-size: 16px; font-weight: lighter;"
                        :disabled="createprogress"
                        @click="cleardata()"
                      >
                        {{ $t("direct_upload.clearfile") }}
                      </v-btn>
                    </v-flex>
                    <v-flex sm6 class="text-left pl-6">
                      <v-btn
                        class="white--text"
                        color="#23526c"
                        style="font-size: 16px; font-weight: lighter;"
                        height="30px"
                        width="80%"
                        :disabled="totalstackfile === 0"
                        @click="fnLoopUploadFile()"
                      >
                        {{ $t("direct_upload.uploadfile") }}
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-flex>
            </v-layout>
          </v-card>
          <!-- จอ xs -->
          <v-card
            v-else-if="resolutionScreen === 220"
            style="margin-top: 10px; border-radius: 25px; padding-top: 10px; padding-bottom: 20px;"
            elevation="2"
            width="100%"
            height="100%"
          >
            <v-card-text class="px-8 pt-6 mb-n12">
              <v-layout>
                <v-flex lg12>
                  <p style="font-size: 16px">
                    {{ $t("direct_upload.savedriveof") }}
                    <!-- <span v-if="$t('default') === 'th'" style="color: #386e50; font-weight: 600;">{{
                      userdata.data_name_th
                    }}</span>
                    <span v-else-if="$t('default') === 'en'" style="color: #386e50; font-weight: 600;">{{
                      userdata.data_name_eng
                    }}</span> -->
                    <!-- ชั่วคราว -->
                    <span style="color: #386e50; font-weight: 600;">{{
                      userdata.data_name
                    }}</span>
                  </p>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-text style="height: auto;">
              <v-layout>
                <v-flex lg12 class="px-2 mb-12">
                  <v-checkbox
                    class="checkboxStyle"
                    v-model="checkhashtag"
                    dense
                    :label="$t('selectbutton.hashtag')"
                  ></v-checkbox>
                  <div class="mb-6 mt-n11 text-right">
                    <v-layout row wrap class="mx-1">
                      <v-flex lg12 v-if="totalstackfile <= 0">
                        <v-chip outlined color="#FFA000" small>
                          <p
                            style="font-size: 12px; font-weight: 600;"
                            class="mt-4"
                          >
                            {{ $t("direct_upload.nofilewaitupload") }}
                          </p>
                        </v-chip>
                      </v-flex>
                      <v-flex lg12 small v-else>
                        <v-chip color="success">
                          <p style="font-size: 12px" class="mt-4">
                            <span style="font-weight: 600;">
                              {{ totalstackfile }}
                            </span>
                            {{
                              totalstackfile > 1
                                ? $t("direct_upload.files")
                                : $t("direct_upload.file")
                            }}
                          </p>
                        </v-chip>
                      </v-flex>
                    </v-layout>
                  </div>
                  <v-row class="my-n2" v-if="checkhashtag === true">
                    <v-col cols="9" class="my-n4">
                      <v-text-field
                        height="35px"
                        class="textFieldClass"
                        v-model="hashtag"
                        label="#hashtag"
                        outlined
                        dense
                        :error-messages="hashtagErrors"
                        @input="$v.hashtag.$touch()"
                        @blur="$v.hashtag.$touch()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3" class="ml-n3 mt-n4">
                      <v-btn
                        class="white--text"
                        color="primary"
                        @click="addhashtag(), $v.hashtag.$reset()"
                        :disabled="hashtagErrors.length > 0 ? true : false || hashtag.length === 0"
                        height="35px"
                      >
                        {{ $t("edithashtag.add") }}</v-btn
                      >
                    </v-col>
                    <v-col cols="12">
                      <v-chip
                        v-for="(item, index) in datahashtag"
                        :key="index"
                        close
                        class="ma-1"
                        @click:close="deletehashtag(index)"
                        >#{{ item }}</v-chip
                      >
                    </v-col>
                  </v-row>
                  <!-- upload layout -->
                  <div
                    style="border-radius: 24px; position: relative;"
                    class="text-center mt-4 mx-auto"
                    id="dropzone"
                    v-if="totalstackfile > 0"
                  >
                    <div @click="ImportFiles" id="inputfile">
                      <input
                        ref="importdoc"
                        type="file"
                        @change="fnStackFile"
                        multiple
                        class="d-none"
                      />
                    </div>
                    <v-list
                      class="pt-2"
                      id="layouthover"
                      dense
                      style="z-index:3; overflow-y: scroll; height: 320px;"
                    >
                      <v-list-item
                        v-for="item in arraydataupload"
                        :key="item.id"
                        class="my-n4"
                      >
                        <v-list-item-action class="mr-4">
                          <v-icon large :color="item.icon[1]">{{
                            item.icon[0]
                          }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content class="mr-n2 text-left">
                          <v-list-item-title style="font-size: 14px;">
                            {{ item.name }}
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action class="text-right">
                          <!-- ปุ่ม cancel upload -->
                          <v-icon
                            class="ml-2"
                            @click="fnDeleteStackFile(item)"
                            color="red"
                            >mdi-close</v-icon
                          >
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                    <div class="mb-n6">
                      <span
                        id="titleTextUploadtitle"
                        class="mr-2"
                        style="font-size:16px;"
                        >{{ $t("uploadfile.clicktouploadnew") }}</span
                      >
                    </div>
                    <dir>
                      <span
                        id="titleTextUploadsubtitle"
                        style="font-size:14px;"
                        >{{ $t("uploadfile.maxsizeuploadtext") }}</span
                      >
                    </dir>
                  </div>
                  <div
                    v-else
                    :style="uploadLayout"
                    class="text-center mt-3 pt-12 pb-6  mx-auto"
                    @click="ImportFiles"
                    id="dropzone"
                  >
                    <div id="layouthover">
                      <input
                        ref="importdoc"
                        class="d-none"
                        type="file"
                        @change="fnStackFile"
                        multiple
                        style="display:none"
                      />
                      <v-img
                        max-width="240"
                        max-height="210"
                        class="mx-auto"
                        :src="require('@/assets/img/uploadfiles.png')"
                      />
                      <span id="titleTextUploadtitle" style="font-size:16px">{{
                        $t("uploadfile.clicktouploadnew")
                      }}</span>
                      <br />
                      <span
                        id="titleTextUploadsubtitle"
                        style="font-size:14px"
                        >{{ $t("uploadfile.maxsizeuploadtext") }}</span
                      >
                      <br />
                      <br />
                    </div>
                  </div>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-text v-if="statusuploading === true" class="mb-n8">
              <v-layout class="mt-n10">
                <v-flex lg12 class="px-2">
                  <v-alert
                    border="left"
                    color="#EF9A9A"
                    dark
                    v-if="totalerror > 0"
                  >
                    <v-progress-linear
                      color="error"
                      :value="percentuploadedallfile"
                      height="30"
                    >
                      <span
                        >{{
                          statusstorage === true
                            ? $t("direct_upload.yourspacenotenough")
                            : $t("direct_upload.uploadfail") +
                              " " +
                              totalerror +
                              " "
                        }}
                        {{
                          totalerror === 1
                            ? $t("direct_upload.file")
                            : $t("direct_upload.files")
                        }}</span
                      >
                    </v-progress-linear>
                  </v-alert>
                  <v-alert
                    border="left"
                    color="#79b5d9"
                    dark
                    v-else-if="percentuploadedallfile !== 100"
                  >
                    <v-progress-linear
                      color="#204f68"
                      :value="percentuploadedallfile"
                      height="30"
                    >
                      <span>{{ parseInt(percentuploadedallfile) }}%</span>
                    </v-progress-linear>
                  </v-alert>
                  <v-alert v-else border="left" color="#b0dcca" dark>
                    <v-progress-linear color="#5cb484" value="100" height="30">
                      <span>{{ $t("direct_upload.uploadsuccess") }}</span>
                    </v-progress-linear>
                  </v-alert>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import sizeDataConverter from "@/globalFunctions/sizeDataConverter";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  mixins: [validationMixin],
  validations: {
    hashtag: {  },
  },
  components: {
    // onebox_toolbar
  },
  data: function() {
    return {
      fail_: false,
      errormessage: "",
      defaultflag: "",
      language: [
        {
          flag: "us",
          name: "languages.english",
          data: "en",
        },
        {
          flag: "th",
          name: "languages.thai",
          data: "th",
        },
      ],
      userdata: {},
      checkhashtag: false,
      datahashtag: [],
      tempdatahashtag: [],
      hashtag: "",
      arraydataupload: [],
      temparraydataupload: [],
      openuploadlayout: false,
      statusuploading: false,
      totalstackfile: 0,
      createprogress: false,
      stackfileloading: false,
      statusstorage: false,
      checkorientation: false,
    };
  },
  filters: {
    subStr: function(string) {
      if (string == null || string == "" || string == undefined) {
        return string;
      } else {
        if (string.length > 15) return string.substring(0, 15) + "...";
        else return string;
      }
    },
  },
  computed: {
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    colorVapp() {
      // return "background-color: #cce4d4;";
      return "background-color: #e8eff2; overflow-y:hidden";
    },
    //style upload layout ของ mobile
    uploadLayout() {
      if (this.createprogress === true) {
        return "border-radius: 24px; cursor: not-allowed;";
      } else {
        return "border-radius: 24px; cursor: pointer;";
      }
    },
    percentuploadedallfile() {
      return (
        this.temparraydataupload.reduce((a, b) => {
          let x = b["value"] === 500 ? 100 : b["value"];
          let sum = a + x;
          return sum;
        }, 0) / this.temparraydataupload.length
      );
    },
    totalerror() {
      return (
        this.temparraydataupload.filter((item) => {
          return item.status === "fail";
        }).length || 0
      );
    },
    hashtagErrors() {
      let errors = [];
      if (this.$v.hashtag.$dirty === false) {
        return errors;
      } else if (this.$v.hashtag.required === false) {
        errors.push(this.$t("edithashtag.hashtagempty"));
        return errors;
      } else {
        let allow = true;
        let specialChar = [",", " ", "|"];

        for (let index = 0; index < specialChar.length; index++) {
          const element = specialChar[index];
          if (this.hashtag.includes(element)) {
            allow = false;
            break;
          } else {
            continue;
          }
        }
        if (allow === false) {
          errors.push(this.$t("edithashtag.nospecialcharacter"));
          return errors;
        } else {
          return errors;
        }
      }
    },
  },
  methods: {
    // เปลี่ยนภาษา
    changelang(parameter) {
      localStorage.setItem("lang", parameter);
      this.$i18n.locale = parameter;
      let dflag = this.language.findIndex((v) => v.data === parameter);
      this.defaultflag = this.language[dflag]["flag"];
    },
    // กำหนดไอคอน
    seticon(parameter) {
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls") {
        dataicon = ["mdi-file-excel", "#00733b"];
      } else if (parameter === "docx" || parameter === "doc") {
        dataicon = ["mdi-file-word", "#0263d1"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint", "#e03303"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf", "#e5252a"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text", "blue-grey"];
      } else if (
        parameter === "jpeg" ||
        parameter === "jpg" ||
        parameter === "png"
      ) {
        dataicon = ["mdi-file-image", "#0ac963"];
      } else if (
        parameter === "avi" ||
        parameter === "mpeg" ||
        parameter === "mov" ||
        parameter === "mp4" ||
        parameter === "mkv" ||
        parameter === "wmv" ||
        parameter === "3gp" ||
        parameter === "flv"
      ) {
        dataicon = ["mdi-file-video", "purple"];
      } else if (parameter === "shortcut") {
        dataicon = ["mdi-file-link", "pink"];
      } else if (parameter === "r" || parameter === "folder") {
        dataicon = ["mdi-folder", "#ffc107"];
      } else {
        dataicon = ["mdi-file", "black"];
      }

      return dataicon;
    },
    // ลบไฟล์ที่เก็บ
    fnDeleteStackFile(filedata) {
      var index = this.arraydataupload.indexOf(filedata);
      this.arraydataupload.splice(index, 1);
      this.totalstackfile = this.totalstackfile - 1;
    },
    // ลบ event
    removeEvent() {
      var self = this;
      self.closeDragDrop = true;
      window.removeEventListener("dragenter", self.dragenter, true);
      window.removeEventListener("dragleave", self.dragleave, true);
      window.removeEventListener("dragover", self.dragover, true);
      window.removeEventListener("drop", self.drop, true);
      window
        .matchMedia("(orientation: portrait)")
        .removeListener(this.fnOrientation);
    },
    // เพิ่ม event dragdrop
    addEvent() {
      if (this.resolutionScreen >= 500) {
        // ######### เช็คว่าหน้าจอเล็กไม่ให้ drag drop ได้ #########
        var self = this;
        self.closeDragDrop = false;
        window.addEventListener("dragenter", self.dragenter, true);
        window.addEventListener("dragleave", self.dragleave, true);
        window.addEventListener("dragover", self.dragover, true);
        window.addEventListener("drop", self.drop, true);
      }
    },
    // เพิ่ม event Oriention
    addEventOrientation() {
      window
        .matchMedia("(orientation: portrait)")
        .addListener(this.fnOrientation);
      let height = window.screen.height;
      let width = window.screen.width;
      if (height > width) {
        this.checkorientation = false;
      } else {
        this.checkorientation = true;
      }
    },
    fnOrientation(e) {
      if (e.matches) {
        this.checkorientation = false;
      } else {
        this.checkorientation = true;
      }
    },
    // ลากเข้าไปโซน
    dragenter(e) {
      if (this.createprogress === false) {
        document.querySelector("#dropzone").style.border = "4px dashed #448060";
        document.querySelector("#dropzone").style.background = "white";
        document.querySelector("#layouthover").style.background = "#aeb3b5";
        document.querySelector("#layouthover").style.opacity = "0.4";
      }
    },
    // ลากเข้าเช็คทุกๆ 200 milliseconds
    dragover(e) {
      if (this.createprogress === false) {
        e.preventDefault();
        e.stopPropagation();
        document.querySelector("#dropzone").style.border = "4px dashed #448060";
        document.querySelector("#dropzone").style.background = "white";
        document.querySelector("#layouthover").style.background = "#aeb3b5";
        document.querySelector("#layouthover").style.opacity = "0.4";
      }
    },
    // ลากออก
    dragleave(e) {
      if (this.createprogress === false) {
        e.preventDefault();
        e.stopPropagation();
        document.querySelector("#dropzone").style.border = "4px dashed #5fb588";
        // document.querySelector("#dropzone").style.background = "#e8eff2";
        // document.querySelector("#layouthover").style.background = "#e8eff2";
        document.querySelector("#layouthover").style.opacity = "1";
      }
    },
    // ลากแล้วปล่อยไฟล์
    async drop(e) {
      if (this.createprogress === false) {
        e.preventDefault();
        e.stopPropagation();
        document.querySelector("#dropzone").style.border = "4px dashed #5fb588";
        document.querySelector("#dropzone").style.background = "#e8eff2";
        document.querySelector("#layouthover").style.background = "#e8eff2";
        document.querySelector("#layouthover").style.opacity = "1";
        let items = e.dataTransfer;
        this.fnStackFileDrug(items.files);
      }
    },
    // เด้งให้เลือกไฟล์
    ImportFiles() {
      if (this.createprogress === false) {
        this.selectingImportFiles = true;
        this.checkfirstloopchoice = false;
        this.fileImport = null;
        this.$refs.importdoc.value = null;
        window.addEventListener(
          "focus",
          () => {
            this.selectingImportFiles = false;
          },
          { once: true }
        );
        this.$refs.importdoc.click();
      }
    },
    // ลูปการเก็บไฟล์ของการเลือกไฟล์
    async fnStackFile(e) {
      this.temparraydataupload = [];
      this.statusuploading = false;
      this.statusstorage = false;
      this.fileImport = e.target.files;
      let i = this.totalstackfile;
      let totalallfile = this.totalstackfile + this.fileImport.length;
      let numcreateformdata = 0;
      this.stackfileloading = true;
      for (i; i < totalallfile; i++) {
        let formData = await this.createPayloadUpload(i, numcreateformdata);
        this.arraydataupload[i].formData = formData;
        this.totalstackfile = this.totalstackfile + 1;
        numcreateformdata = numcreateformdata + 1;
        if (totalallfile === i + 1) {
          this.stackfileloading = false;
        }
      }
    },
    // ลูปการเก็บไฟล์ของการลากไฟล์
    async fnStackFileDrug(files) {
      this.temparraydataupload = [];
      this.statusuploading = false;
      this.statusstorage = false;
      this.fileImport = files;
      let i = this.totalstackfile;
      let totalallfile = this.totalstackfile + this.fileImport.length;
      let numcreateformdata = 0;
      this.stackfileloading = true;
      for (i; i < totalallfile; i++) {
        let formData = await this.createPayloadUpload(i, numcreateformdata);
        this.arraydataupload[i].formData = formData;
        this.totalstackfile = this.totalstackfile + 1;
        numcreateformdata = numcreateformdata + 1;
        if (totalallfile === i + 1) {
          this.stackfileloading = false;
        }
      }
    },
    // สร้าง payload สำหรับอัปโหลด
    async createPayloadUpload(i, numcreateformdata) {
      let typefile;
      if (!this.arraydataupload[i]) {
        // ปั้นดาต้า
        typefile = this.fileImport[numcreateformdata].name.split(".");
        typefile = typefile[typefile.length - 1]
        let iconfile = this.seticon(typefile);
        
        let datafileupload = {};
        datafileupload["file"] = this.fileImport[numcreateformdata];
        datafileupload["icon"] = iconfile;
        datafileupload["name"] = this.fileImport[numcreateformdata].name;
        datafileupload["size"] = this.fileImport[numcreateformdata].size;
        datafileupload["value"] = 0;
        datafileupload["status"] = "start";
        datafileupload["msg"] = "";
        datafileupload["colorupload"] = "primary";
        datafileupload["fileduplicate"] = "";
        datafileupload["formData"] = "";
        datafileupload["type"] = typefile;
        this.arraydataupload.push(datafileupload);
      }
      const formData = new FormData();
      formData.append("file", this.arraydataupload[i].file);
      formData.append("data_id", this.userdata.data_id);
      formData.append("data_type", typefile);
      formData.append("account_id", this.userdata.account_id);
      formData.append("user_id", this.userdata.user_id);
      formData.append("folder_id", this.userdata.folder_id);
      return formData;
    },
    // ลูปสำหรับการอัปโหลดไฟล์
    async fnLoopUploadFile() {
      this.totalstackfile = 0;
      this.statusuploading = true;
      this.createprogress = true;
      document.querySelector("#dropzone").style.opacity = "0.4";
      this.temparraydataupload = [...this.arraydataupload];
      this.tempdatahashtag = [...this.datahashtag];
      this.checkhashtag = false;
      this.arraydataupload = [];
      for (let i = 0; i < this.temparraydataupload.length; i++) {
        await this.fnCheckStorage();
        await this.fnUploadFile(this.temparraydataupload[i].formData, i);
      }
    },
    // เรียกใช้ api สำหรับอัปโหลด
    async fnUploadFile(formData, i) {
      if (this.userdata.remain_storage > this.temparraydataupload[i].size) {
        if (
          this.tempdatahashtag !== "" ||
          this.tempdatahashtag !== null ||
          this.tempdatahashtag !== undefined
        ) {
          for (let i = 0; i < this.tempdatahashtag.length; i++) {
            formData.append("hashtag", this.tempdatahashtag[i]);
          }
        } else {
          formData.append("hashtag", "");
        }
        let auth = await gbfGenerate.generateToken();
        this.axios({
          method: "POST",
          url:
            process.env.VUE_APP_SERVICE_UPLOAD_API +
            "/api/oneplatform/uploads_file",
          data: formData,
          headers: { Authorization: auth.code },
          onUploadProgress: (e) => {
            if ((e.loaded / e.total) * 100 >= 95) {
              this.temparraydataupload[i]["value"] = 95;
            } else {
              this.temparraydataupload[i]["value"] = parseInt(
                (e.loaded / e.total) * 100
              );
            }
          },
        })
          .then((response) => {
            if (response.data.status === "OK") {
              this.temparraydataupload[i].status = "success";
              this.temparraydataupload[i].msg = this.$t(
                "uploadfile.uploadsuccess"
              );
              this.temparraydataupload[i].colorupload = "success";
              this.temparraydataupload[i].value = 100;
            } else {
              this.temparraydataupload[i].status = "fail";
              this.temparraydataupload[i].msg = this.$t(
                "dragdrop.cannotdetailupload"
              );
              this.temparraydataupload[i].colorupload = "error";
            }
            if (this.temparraydataupload.length === i + 1) {
              document.querySelector("#dropzone").style.display = "auto";
              document.querySelector("#dropzone").style.opacity = "1";
              this.addEvent();
              this.createprogress = false;
            }
          })
          .catch((thrown) => {
            console.log(thrown);
            if (this.temparraydataupload.length === i + 1) {
              document.querySelector("#dropzone").style.display = "auto";
              document.querySelector("#dropzone").style.opacity = "1";
              this.addEvent();
            }
          });
      } else {
        this.statusstorage = true;
        this.temparraydataupload[i].status = "fail";
        this.temparraydataupload[i].colorupload = "error";
        this.createprogress = false;
      }
    },
    // ลบแฮชแท็ก
    deletehashtag(index) {
      this.datahashtag.splice(index, 1);
    },
    // เพิ่มแฮชแท็ก
    addhashtag() {
      this.datahashtag.push(this.hashtag);
      this.hashtag = "";
    },
    //เคลียร์ข้อมูล
    async cleardata() {
      this.selectingimportFiles = false;
      this.fileImport = null;
      this.arraydataupload = [];
      this.temparraydataupload = [];
      this.statusuploading = false;
      this.totalstackfile = 0;
      this.statusstorage = false;
      this.datahashtag = [];
      this.tempdatahashtag = [];
    },
    // ล็อกอิน
    async loginwithlogindirecupload() {
      if (!this.$route.query.accesstoken) {
        this.fail_ = true;
        this.errormessage = "Accesss Denied !!";
      } else {
        try {
          let payload = {};
          if (!this.$route.query.biz_id) {
            payload = {
              accesstoken: this.$route.query.accesstoken,
            };
          } else {
            payload = {
              accesstoken: this.$route.query.accesstoken,
              biz_id: this.$route.query.biz_id,
            };
          }
          let auth = await gbfGenerate.generateToken();
          var response = await this.axios.post(
            process.env.VUE_APP_SERVICE_AUTHORIZE_API +
              "/api/oneplatform/login/uploads",
            payload,
            {
              headers: { Authorization: auth.code },
            }
          );
          if (response.data.status === "OK") {
            this.userdata = response.data.data;
            this.openuploadlayout = true;
            this.fnCheckStorage();
          } else {
            this.fail_ = true;
            this.errormessage = this.$t("direct_upload.cannotlogin");
          }
        } catch (err) {
          console.log(err);
          Toast.fire({
            icon: "error",
            title: err,
          });
        }
      }
    },
    // เช็คพื้นที่ข้อมูล
    async fnCheckStorage() {
      try {
        let payload = {
          account_id: this.userdata.account_id,
          convert_storage: "True",
        };
        let auth = await gbfGenerate.generateToken();
        let response = await this.axios.post(
          process.env.VUE_APP_SERVICE_AUTHORIZE_BUSINESS_CHECK_STORAGE +
            "/api/check_used_storage",
          payload,
          { headers: { Authorization: auth.code } }
        );
        if (response.data.status === "OK") {
          let remain = await sizeDataConverter.convertStorageToByte(
            response.data.result[0].remain_storage
          );
          this.userdata.remain_storage = remain;
        } else {
          console.log("res", response);
        }
      } catch (err) {
        console.log("err", err);
      }
    },
  },
  mounted() {
    this.loginwithlogindirecupload();
    this.cleardata();
    this.createprogress = false;
  },
  created() {
    this.addEvent();
    this.addEventOrientation();
    this.changelang(localStorage.getItem("lang"));
  },
  beforeDestroy() {
    this.removeEvent();
    this.cleardata();
    this.createprogress = false;
  },
};
</script>
<style scoped>
.drag-selector {
  padding: 15px;
}
.drag-selector__item {
  align-items: center;
}
template td {
  padding: 0px;
  margin: 0px;
}
.v-snack__content {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}
#layouthover {
  background: #e8eff2;
  border-radius: 25px;
  transition: background 200ms, opacity 200ms, height 175ms, width 175ms,
    border 300ms;
  opacity: 1;
}

div#dropzone {
  border: 4px dashed #5fb588;
  font: bold 42px Sarabun, system-ui, Tahoma, sans-serif;
  background: #e8eff2;
}

#inputfile {
  border-radius: 23px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
}

#titleTextUploadtitle {
  color: black;
  font-size: 18px;
  line-height: 24px;
}
#titleTextUploadsubtitle {
  color: red;
  font-size: 16px;
  line-height: 24px;
}
.checkboxStyle .v-label {
  font-size: 14px;
  color: black;
}
.textFieldClass .v-label {
  font-size: 14px;
  display: flex !important;
}
</style>
